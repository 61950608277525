<template>
  <div name="activityview">
    <innertopbar @close="closeWindow()">
      <template slot="breadcrumb">
        <router-link to="activities"> Activities </router-link>
        <i class="carret fas fa-caret-right"></i> {{ title }}
      </template>

      <template slot="listitems">
        <li
          v-if="!newAddition"
          @click="deletion"
          title="Delete Activity"
          class="action-list-item rounded-lg inline-block hover:opacity-100 pt-2"
        >
          <i class="icon fas fa-trash-alt" title="Delete Activity"></i>
          <p class="inline text-xs p-1">Delete</p>
        </li>

        <li
          v-if="!newAddition"
          class="inline-block mx-4 text-2xl text-gray-400"
        >
          |
        </li>

        <li class="inline-block">
          <button
            @click="submitForm()"
            type="button"
            class="publish-button hover:opacity-75"
          >
            Publish Changes
          </button>
        </li>
      </template>
    </innertopbar>


        <transition name="fade">
      <div class="loading" v-show="isSaving">
        <span class="fa fa-spinner fa-spin"></span> Saving
      </div>
    </transition>

    <div class="w-full mx-auto grid grid grid-flow-col grid-cols-2 gap-10">
      <div class="mt-6 ml-8 p-6 bg-white rounded-lg mb-10">
        <form
          class="w-95p mx-auto"
          v-on:submit.prevent="submitActivity"
          id="activityForm"
          ref="form"
        >
          <savenotice
            :title="'Pupil'"
            ref="saveNoticeTop"
            class="right"
          ></savenotice>

          <div class="flex flex-wrap -mx-3 mb-8">
            <div class="w-full">
              <label class="form-label" for="grid-title"> Activity Title </label>
              <input
                v-model="name"
                class="mb-2 focus:border-red-200 focus:bg-white focus:outline-none"
                id="grid-title"
                type="text"
                placeholder="Enter activity title"
              />
              <p class="text-red-300 text-xs italic">
                Please fill out this field.
              </p>
            </div>
          </div>

          <div class="flex flex-wrap -mx-3 mb-8">
            <div class="w-full">
              <label class="form-label" for="grid-title"> Activity Terms </label>

              <div class="flex flex-wrap">
              <div v-for="term in terms" :key="term.key" @click="addSelectedTerm(term)"
              class="mr-3 mb-2 rounded p-2 px-5 cursor-pointer   hover:bg-gray-400 hover:bg-opacity-50 hover:text-action-blue"
               v-bind:class="inSelectedTerms(term) ? 'bg-action-blue bg-opacity-75 text-white': 'bg-gray-200 text-gray-600'">
                  <i class="far pr-1" v-bind:class="inSelectedTerms(term) ? 'fa-check-square': 'fa-square'" ></i> {{term.name}}
              </div>
              </div>
                 <p class="text-red-300 text-xs italic">
                Please fill out this field.
              </p>
            </div>
          </div>

        <div class="flex flex-wrap -mx-3 mb-8">
            <div class="w-full">
              <label class="form-label" for="grid-title"> Activity Days </label>

              <div class="flex flex-wrap">
              <div   v-for="day in days" v-bind:key="day" @click="addSelectedDay(day)"
                        class="mr-2 mb-2 rounded p-2 px-4 cursor-pointer   hover:bg-gray-400 hover:bg-opacity-50 hover:text-action-blue"
               v-bind:class="inSelectedDays(day) ? 'bg-action-blue bg-opacity-75 text-white': 'bg-gray-200 text-gray-600'">
                  <i class="far pr-1" v-bind:class="inSelectedDays(day) ? 'fa-check-square': 'fa-square'" ></i> {{shortDate(day)}}
              </div>
              </div>
                 <p class="text-red-300 text-xs italic">
                Please fill out this field.
              </p>
            </div>
          </div>


          <div class="flex flex-wrap -mx-3 mb-8">
            <div class="w-full">
              <label class="form-label" for="grid-title"> Activity Times </label>

              <div class="flex flex-wrap">
              <div v-for="time in timesframes" :key="time.time_value" @click="addSelectedTime(time)"
              class="mr-3 mb-2 rounded p-2 px-5 cursor-pointer   hover:bg-gray-400 hover:bg-opacity-50 hover:text-action-blue"
               v-bind:class="inSelectedTimes(time) ? 'bg-action-blue bg-opacity-75 text-white': 'bg-gray-200 text-gray-600'">
                  <i class="far pr-1" v-bind:class="inSelectedTimes(time) ? 'fa-check-square': 'fa-square'" ></i>  {{time.title}}
              </div>
              </div>
                 <p class="text-red-300 text-xs italic">
                Please fill out this field.
              </p>
            </div>
          </div>

          
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full md:w-1/2 pr-3 mb-6 md:mb-0">
               <label class="form-label" for="grid-title">
                Activity Duration (minutes)
              </label>
              <input
                v-model="duration"
                class="mb-2 text-center w-full bg-gray-100 h-10"
                id="grid-title"
                type="number"
                placeholder="Enter activity duration"
              />
            </div>

            <div class="w-full md:w-1/2 pl-3">
              <label class="form-label" for="grid-title">
                Number of weeks
              </label>
              <input
                v-model="weeksCount"
                class="mb-2 text-center w-full bg-gray-100 h-10"
                id="grid-title"
                type="number"
                placeholder="Enter weeks count"
              />
            </div>
          </div>

          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full pl-3">
             <label class="form-label" for="grid-title"> Activity Type </label>
              <dropdown
                :options="typeDropdown"
                :selected="selectedType"
                v-on:updateOption="typeOnSelect"
                class="bg-gray-100"
              ></dropdown>
            </div>
          </div>

          <div class="flex flex-wrap -mx-3 mb-8">
            <div class="w-full">
              <label class="form-label" for="grid-title"> Activity Address </label>

              <input
                v-model="address"
                class="mb-2 focus:border-red-200 focus:bg-white focus:outline-none"
                type="text"
                placeholder="Enter activity address"
              />
            </div>
          </div>

          <div class="flex flex-wrap -mx-3 mb-8">
            <div class="w-full">
              <label class="form-label" for="grid-title"> Activity Details </label>

              <textarea
                v-model="details"
                cols="30"
                rows="10"
                class="p-2 focus:border-red-200 focus:bg-white focus:outline-none"
              ></textarea>
            </div>
          </div>

          <savenotice
            :title="'Pupil'"
            ref="saveNoticeBottom"
            class="right"
          ></savenotice>
        </form>
      </div>


      <div class="p-6 pt-10 bg-white">
        <div class="block h-1/2 overflow-y-auto">
          <h3 class="mb-6 ml-1 text-lg font-semibold">
            {{ pupilsSelected.length }} pupils at the activity
            <button
              @click="showPupilGrid = true"
              type="button"
              class="
                action-button
                icon
                hover:opacity-75
                fa-plus
                p-2
                py-1
                text-sm
                float-right
                mr-4
              "
            ></button>
          </h3>

                                       <div
        style="width: 100%; text-align: center"
        class="spin-holder mt-4"
        v-if="loading"
      >
         <i class="spinner fa fa-spinner fa-spin"></i>
        <p>Loading Pupils</p>
      </div>

          <table class="table-auto w-full max-h-full" v-if="hasPupils">
            <thead class="bg-gray-100">
              <tr>
                <th class="w-7/12 text-left pl-2 py-1">Name</th>
                <th class="w-3/12 text-center pl-2">Year Group</th>
                <th class="w-2/12 text-center"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="hover:bg-gray-300 cursor-pointer even:bg-gray-100"
                @click="selectPupil(pupil)"
                v-for="pupil in pupilsSelectedList"
                :key="pupil.identifier"
              >
                <td>{{ pupil.name }}</td>
                <td class="text-center">{{pupilYearGroupDisplay(pupil)}}</td>
                <td class="text-center">
                  <i
                    class="fas fa-check text-base"
                    v-bind:class="selectedPupilStyle(pupil)"
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>

          <p class="m-5 mx-12" v-else>
            We could not find any data for your given selection.
          </p>
        </div>

        <div class="h-72 block" style="display:none;">
          <h3 class="mb-6 mt-4 ml-6 text-lg font-semibold">
            {{ pupils.length }} evidences for the event

            <button
              type="button"
              class="
                action-button
                icon
                hover:bg-gray-300
                fa-plus
                p-2
                py-1
                text-sm
                float-right
                mr-4
              "
            ></button>
          </h3>
        </div>
      </div>
    </div>

    <transition name="modalfile" v-if="showPupilGrid">
      <div
        class="modal-mask fixed top-0 left-0 w-full h-auto outline-none fade"
      >
        <div
          class="
            modal-container
            w-full
            mb-10
            mx-auto
            shadow-lg
            z-50
            h-full
            mt-6
          "
        >
          <div class="h-screen overflow-y-auto w-11/12 mx-auto rounded-xl">
            <pupilsselect
              :selectedPupils="pupilsSelectedList"
              @pupil-selected="selectPupil"
              :allowMultiple="true"
              :isInModal="true"
              @close="closePupilSelection"
              class="bg-gray-100 min-h-screen pb-20"
            ></pupilsselect>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
const innertopbar = () => import("@/components/InnerTopBar.vue");
const savenotice = () => import("@/components/SaveNotice.vue");
const dropdown = () => import("@/components/Dropdown.vue");
const pupilsselect = () => import("@/components/PupilsGridSelect.vue");


import { mapGetters } from "vuex";
import { ActivityService } from "@/services/activity.service";

import { termList, eventTypes, timesframes   } from "@/assets/js/utils";
import moment from "moment";

import { yearGroupMixin} from "@/mixins/yearGroupMixin.js";

export default {
    name: "activityview",
  mixins: [yearGroupMixin],
  props: {
    newAddition: { type: Boolean, required: true },
    activity: { type: Object, required: true },
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      currentAccess: "currentAccess",
    }),
    isDisabled() {
      return false;
    },
    typeDropdown() {
      var results = [{ id: 0, name: "Select a type" }].concat(this.types);
      if (this.activity.identifier === undefined) {
        this.selectedType = results[1];
      }
      return results;
    },
    hasPupils() {
      return this.pupilsSelected.length > 0;
    },
    title() {
      if (this.newAddition) {
        return "Create";
      } else {
        return this.name;
      }
    },
    pupilsSelectedList() {
      return this.pupilsSelected;
    },
  },
  data() {
    return {
      school: this.$store.getters.school,
      eventInEdit: true,
      name: "",
      details: "",
      address: "",
      selectedType: {},
      duration: 45,
      weeksCount: 0,
      pupils: [],
      pupilsSelected: [],
      pupilsRemoved: [],
      loading: false,
      errored: false,
      types: eventTypes(),
      showPupilGrid: false,
      timesframes: timesframes(),
      terms: termList(),
      days: [],
      selectedTerms:[],
      selectedTimes:[],
      selectedDays: [],

      isSaving: false,

    };
  },
  created() {
    if (this.newAddition == false) {
      if (this.activity == undefined) {
        this.closeWindow();
        return;
      } else {
        this.name = this.activity.title == undefined ? "" : this.activity.title;
        this.address = this.activity.address.String;
        this.details = this.activity.description.String;
        this.duration =
          this.activity.duration.Valid === true ? this.activity.duration.Int64 : 45;
       this.weeksCount =
          this.activity.weeks.Valid === true ? this.activity.weeks.Int64 : 0;

        var foundType = this.types
          .find((tt) => {
            return tt.value == this.activity.session_type.String;
          })         
        this.selectedType = foundType != undefined ? foundType : this.types[0]
        this.selectedTerms = this.activity.term.split(",")
        this.selectedDays = this.activity.weekday.split(",")
        this.selectedTimes = this.activity.timeframe.split(",")

        this.fetchPupils();
      }
    }
  },
  mounted() {
    moment.locale("en-gb");
    this.days = moment.weekdays(true);
  },
  methods: {
    fetchPupils() {
      this.loading = true;

      ActivityService.getPupils(this.activity)
        .then((response) => (this.pupils = response.data))
        .catch((err) => {
          console.log("activities pupils " + err);
          this.errored = true;
        })
        .finally(() => {
          this.pupilsSelected = this.pupils.slice();
          this.loading = false;
        });
    },
    pupilYearGroupDisplay(pupil) {
      let found = this.getYearGroupFromValue(pupil.yeargroup) 
      return found != undefined ? found.name : "Unknown"
    },
    submitForm() {
      this.isSaving = true

      if (this.$refs.form.requestSubmit === undefined) {
         this.processActivity()
      } else {
        this.$refs.form.requestSubmit();
      }
    },
    submitActivity() {

      if (this.name == "" || 
        this.selectedType.id === 0 || 
        this.selectedTerms.length === 0 ||
          this.selectedTimes.length === 0 ||
            this.selectedDays.length === 0) {
        this.alertDisplay();
        this.isSaving = false
      } else {
        this.processActivity();
      }
    },
    alertDisplay() {
      this.$swal({
        title: "Please complete all fields",
        text: "You must enter a title and set a class to complete the form.",
        type: "warning",
        showCancelButton: false,
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result === true) {
          this.processPupil();
        }
      });
    },
    processActivity() {
      this.activity.title = this.name;

      this.activity.session_type = {
        String: this.selectedType.value,
        Valid: true,
      };

      this.activity.address = {
        String: this.address,
        Valid: true,
      };

      this.activity.description = {
        String: this.details,
        Valid: true,
      };

      this.activity.duration = {
        Int64: parseInt(this.duration),
        Valid: true,
      };

      this.activity.weeks = {
        Int64: parseInt(this.weeksCount),
        Valid: true,
      };

      this.activity.term = this.selectedTerms.join()
      this.activity.weekday = this.selectedDays.join()
      this.activity.timeframe = this.selectedTimes.join()

      if (this.activity.identifier === undefined) {
        ActivityService.add(this.activity)
          .then((response) => {
            this.activity.identifier = response.data.identifier;
            this.completion();
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        ActivityService.update(this.activity)
          .then((response) => this.completion(response.data))
          .catch((err) => {
            console.log(err);
          });
      }
    },
    completion(msg) {
      var vm = this;
      vm.pupilsRemoved.forEach((p) => {
        ActivityService.deletePupil(vm.event, p).catch((err) => {
          console.log(err);
        });
      });

      vm.pupilsSelected.forEach((p) => {
        let payload = {
          activity_identifier: vm.activity.identifier,
          register_identifier: p.identifier,
        };
        ActivityService.addPupil(payload).catch((err) => {
          console.log(err);
        });
      });

      this.isSaving = false
      this.$router.push({ name: "Activities", params: { msg: msg } });
      window.scrollTo(0, 0);
    },
    failure(error) {
      this.$refs.saveNoticeTop.failed();
      this.$refs.saveNoticeBottom.failed();
      console.log("Activity update error " + error);
    },
    typeOnSelect(value) {
      this.selectedType = value;
    },
    inSelectedTerms(term) {
      return this.selectedTerms.includes(term.key)
    },
    addSelectedTerm(term) {
      if (this.selectedTerms.includes(term.key)) {
        this.selectedTerms.splice(this.selectedTerms.indexOf(term.key), 1);
      } else {
        this.selectedTerms.push(term.key);
      }
    },
    inSelectedTimes(time) {
      return this.selectedTimes.includes(time.time_value)
    },
    addSelectedTime(time) {
      if (this.selectedTimes.includes(time.time_value)) {
        this.selectedTimes.splice(this.selectedTimes.indexOf(time.time_value), 1);
      } else {
        this.selectedTimes.push(time.time_value);
      }
    },
    inSelectedDays(day) {
      var dayValue = day.toLowerCase()
      return this.selectedDays.includes(dayValue)
    },
    addSelectedDay(day) {
      var dayValue = day.toLowerCase()
      if (this.selectedDays.includes(dayValue)) {
        this.selectedDays.splice(this.selectedDays.indexOf(dayValue), 1);
      } else {
        this.selectedDays.push(dayValue);
      }
    },
    counterUpdated(value) {
      this.duration = value;
    },
    selectedPupilStyle(pupil) {
      return this.isSelected(pupil) ? "text-green-300" : "text-gray-200";
    },
    shortDate(day) {
      return day.substring(0,3) 
    },
    isSelected(value) {
      let found = this.pupilsSelected.find(
        (p) => p.identifier === value.identifier
      );
      return found;
    },
    selectPupil(pupil) {
      if (this.pupilsSelected.some((p) => p.identifier === pupil.identifier)) {
        this.pupilsSelected.splice(
          this.pupilsSelected.findIndex(
            (item) => item.identifier === pupil.identifier
          ),
          1
        );
        var toRemove = this.pupils.find(
          (p) => p.identifier === pupil.identifier
        );
        if (toRemove != undefined) {
          this.pupilsRemoved.push(pupil);
        }
      } else {
        this.pupilsSelected.push(pupil);
        var toRemove = this.pupilsRemoved.find(
          (p) => p.identifier === pupil.identifier
        );
        if (toRemove != undefined) {
          this.pupilsRemoved.splice(
            this.pupilsRemoved.findIndex(
              (item) => item.identifier === pupil.identifier,
              1
            )
          );
        }
      }
    },
    deletion() {
      this.$swal({
        title: "Are you sure you want to delete?",
        text: "You will not be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete!",
      }).then((result) => {
        if (result) {
          this.processDelete();
        }
      });
    },
    processDelete() {
      ActivityService.delete(this.event)
        .then((response) => this.completion(response.data))
        .catch((err) => {
          console.log(err);
        });
    },
    closeWindow() {
      this.$router.push({ path: "activities" });
      window.scrollTo(0, 0);
    },
    closePupilSelection() {
      this.showPupilGrid = false;
    },
  },
  components: {
    dropdown,
    savenotice,
    innertopbar,
    pupilsselect,
  },
};
</script>

<style scoped>
</style>
